<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>

      <div class="col-auto">
        <button
          @click="$router.push({ name: 'suppliers-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un nouveau fournisseur
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col-auto">Nº</th>
          <th scope="col-2">Référence</th>
          <th scope="col-3">Nom Complet</th>
          <th scope="col-3">Nom Commercial</th>
          <th scope="col-1">Phone</th>
          <th scope="col-3">Ville</th>
          <th scope="col-3">Remarque</th>
          <th scope="col-3"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(supplier, index) in suppliers" :key="index++">
          <td class="col-auto">
            {{ index }}
          </td>
          <td class="col-2">
            {{ supplier.reference }}
          </td>
          <td class="col-3">
            {{ supplier.fullName }}
          </td>

          <td class="col-3">
            {{ supplier.society }}
          </td>

          <td class="col-2">
            {{ supplier.phone }}
          </td>
          <td class="col-3">
            {{ supplier.city }}
          </td>
          <td class="col-3">
            {{ supplier.remark }}
          </td>
          <td class="col-2 d-flex">
            <button
              @click="
                $router.push({
                  name: 'suppliers-edit',
                  params: { reference: supplier.reference },
                })
              "
              class="btn text-secondary"
            >
              <i class="bi bi-pencil-square"></i>
            </button>
            <button class="btn" @click="remove(supplier)">
              <i class="bi bi-trash text-danger"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      maasked_type: false,
      search_value: "",
      addedToday: false,
      sortValue: "",
      display_type_supplier: "",
    };
  },
  computed: {
    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("supplier/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("supplier/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("supplier/getAll");
    },

    async listSuppliers(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("supplier/getTodays");
      } else {
        return await this.$store.dispatch("supplier/getSuppliers");
      }
    },
    async sort(value) {
      return await this.$store.commit("supplier/sort", value);
    },
    async remove(data) {
      let supplier = data;
      await this.$confirm({
        message:
          "Vous voulez supprimer le fournisseur  " +
          supplier.society +
          " " +
          +supplier.society,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("supplier/destroy", supplier.reference);
          }
        },
      });
    },
  },
  filters: {
    masked: function (value, maasked_type, reference, display_type_supplier) {
      if (!maasked_type) return "*********";
      if (reference == display_type_supplier) return value;

      if (reference != display_type_supplier) return "*********";
    },
  },
};
</script>
